<template>
  <section class="orders">
    <div class="page-header">
      <h3 class="page-title"> Commande </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">e-Commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Commande</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Commande</h4>
            <div class="row">
              <!-- filtre par type -->
              <div class="col-md-8">
                <!-- <b-form-group label="" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="filter_by_type"
                    :aria-describedby="ariaDescribedby"
                    name="filter_by_type"
                  >
                    <b-form-checkbox value="all">Tout</b-form-checkbox>
                    <b-form-checkbox value="reservation">Réservation</b-form-checkbox>
                    <b-form-checkbox value="achat">Achat</b-form-checkbox>
                    <b-form-checkbox value="voucher">Carte Cadeau</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group> -->
              </div>

              <!-- recherche -->
              <div class="col-md-4">
                <b-input-group>
                      <b-form-input placeholder="Recherche" type="search" v-model="search"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row overflow-auto">
              <div class="col-12">
                <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <b-table 
                :items="items" 
                id="table-list" 
                responsive :per-page="perPage" 
                :current-page="currentPage" 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc"
                :filter="search"
                @row-clicked="details"
                >

                  <template #cell(client)="row">
                    <span @click="ordersDetails(row.item.ulid)">{{row.item.user.pseudo}}</span>
                  </template>
                  <template #cell(to)="row">
                    <span @click="ordersDetails(row.item.ulid)">{{row.item.deliveryinfos.name}}</span>
                  </template>

                  <template #cell(type)="row">
                    <span v-if="row.item.purchases[0]">
                      <span class="badge badge-outline-default badge-pill" v-if="row.item.purchases[0].product"><i class=" mdi mdi-cart mr-2"></i>Achat</span>
                      <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].service"><i class="mdi mdi-calendar mr-2"></i>Réservation</span>
                      <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].voucher"><i class="fa fa-gift"></i>Carte Cadeau</span>
                      <span v-else>Vide</span>
                    </span>
                  </template>

                  <template #cell(date)="row">
                    <span v-if="row.item.purchases[0]">{{formatDate2(row.item.purchases[0].datepurchase)}}</span>
                  </template>

                  <template #cell(datestart)="row">
                    <span v-if="row.item.purchases[0]">{{formatDate2(row.item.purchases[0].datestart)}}</span>
                  </template>

                  <template #cell(status)="row">
                    <span class="badge badge-primary" v-if="row.item.status==='Préparation'">Préparation</span>
                    <span class="badge badge-success" v-else-if="row.item.status==='Livraison'">Livraison</span>
                  </template>

                  <template #cell(action)="row">
                    <b-button class="btn btn-gradient-danger btn-icon btn-sm" size="sm" @click="deleteOrder(row.item.ulid)"><i class="fa fa-trash"></i></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="right">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require ('axios').default
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { dateMixin } from '../mixins/date';

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  mixins:[dateMixin],
  data: function() {
    return {
      perPage: 10,
      isload:true,
      sortBy: 'name',
        currentPage: 1,
        sortDesc: false,
        sortByFormatted: true,
        filterByFormatted: true,
        sortable: true,
        search:'',
        fields: [
          // { key: 'slugname', sortable: true },
          { key: 'client', label:'Réservé par', sortable: true },
          { key: 'to', label:'pour', sortable: true },
          // { key: 'dateorder', label:'date de commande', sortable: true },
          // { key: 'datedelivery', label:'date de livraison', sortable: true },
          { key: 'type', sortable: true },
          { key: 'date', label:'fait le',sortable: true },
          { key: 'datestart', label: 'pour le', sortable: true},
          { key: 'status', sortable: true },
          { key: 'action', sortable: true }
        ],
        items: [
        ],
        filter_by_type:[]
    };
  },
  watch:{
    filter_by_type(value){
      console.log(value);
      if(value.includes("reservation")){
        this.items=this.items.filter(item => item.purchases[0].service)
      }
      else if(value.includes("achat")){
        this.items=this.items.filter(item => item.purchases[0].product)
      }
      else if(value.includes("voucher")){
        this.items=this.items.filter(item => item.purchases[0].voucher)
      }
    }
  },
  computed: {
      rows() {
        return this.items.length
      }
    },
    methods: {
      getOrders() {
        axios.get('orders')
        .then(resOrders=> {
          {
            this.items= resOrders.data.result
            this.items = this.items.filter(item => item.ulid !="01FY46XE8PN27S18ZJYS2GEFKZ")
            this.isload = false
          }
        })
        .catch(errOrders=> console.log(errOrders)) 
        },
        details(record){
            this.ordersDetails(record.ulid)
        },
      ordersDetails(ulid) {
        this.$router.push({
          name:"ordersDetails",
          params: {
            ulid: ulid
          }
        })
      },
      deleteOrder(order){
         this.$swal({
            title: 'Êtes-vous sûr?',
            text: 'Voulez-vous supprimer cette commande ?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer',
            cancelButtonText: 'Annuler',
        })
        .then((result) => {
            if (result.value) {
            this.isload = true
            axios.delete(`orders/${order}`, {
              headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
                  }
                }
                )
                .then(resDelete => {
                  console.log(resDelete)
                  this.$swal(
                    'Supprimé!',
                    'La commande a bien été supprimée',
                    'success'
                    )   
                  this.getOrders()
                  this.isload = false
                })
                .catch(errDelete => {console.log(errDelete)})
              }
        })
      }
    },
    async created () {
      this.getOrders()
    }
}
</script>